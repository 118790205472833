import * as axi from "axios"

const axios = axi.create({
    baseURL: "/api/v1/",
    headers: {
        //"X-Language": window.app_current_locale
    }
})

export default class LocalizationRepository {
    languages = []
    currencies = []

    static async changeLanguage(lang) {
        window.location = `/locale/${lang}`
    }

    static changeCurrency(id) {
        window.location = `/currency/${id}`
    }

    static async languages(bindToActive = true) {
        let languages = []
        if (this.languages.length) {
            languages = this.languages
        } else {
            const result = await axios.get(`/languages?timestamp=${new Date().getTime()}`)
            languages = result.data.data
        }
        if (languages) {
            if (bindToActive) {
                const current = await LocalizationRepository.currentLanguage()
                languages = [
                    { ...languages.find(locale => locale.iso_2 === current), is_active: true },
                    ...languages.filter(locale => locale.iso_2 !== current).map(l => ({ ...l, is_active: false }))
                ]
            }
            return languages
        }
    }

    static async currencies(bindToActive = false) {
        let currencies = []
        if (this.currencies.length) {
            currencies = this.currencies
        } else {
            const result = await axios.get(`/currencies/main`)
            currencies = result.data.data
        }

        if (currencies) {
            if (bindToActive) {
                const current = await LocalizationRepository.currentCurrency()
                const matched = currencies.find(currency => currency.id === current.id)
                if (matched) {
                    matched.is_active = true
                } else {
                    currencies[0].is_active = true
                }
                currencies.filter(c => !c.is_active).forEach(c => (c.is_active = false))
            }

            return currencies
        }
    }

    static async currentCurrency() {
        if (window.current_currency) {
            return window.current_currency
        }
        const result = await axios.get(`/currencies/current`)
        if (result) {
            return result.data.data
        }
    }

    static async currentLanguage() {
        return window.app_current_locale
    }
}
